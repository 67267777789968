import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyLocation } from "local-types";
import Image, { FixedObject } from "gatsby-image";

import { rhythm } from "../../../utils/typography";
import { device } from "../../../styles/constants";

interface PostCardProps {
  title: string;
  slug: string;
  cover?: FixedObject;
  description: string;
  date: string;
  timeToRead: number;
  location: GatsbyLocation;
}

const PostCard: React.FunctionComponent<PostCardProps> = ({
  title,
  slug,
  cover,
  description,
  date,
  timeToRead,
  location,
}): React.ReactElement => (
  <Root>
    <PostContainer>
        <CoverContainer>
          <Link
          aria-label={`Continue reading: ${title}`}
          to={`/blog${slug}`}
          state={{ prevPath: location.pathname }}
        >
          <Cover fixed={cover} />
          </Link>
        </CoverContainer>
        <DetailsContainer>
            <Title>
            <Link
              aria-label={`Continue reading: ${title}`}
              to={`/blog${slug}`}
              state={{ prevPath: location.pathname }}
            >
              {title}
            </Link>
          </Title>
          <ContentInfo>
            {date} · {timeToRead} min read
          </ContentInfo>
        </DetailsContainer>

        
        <DescriptionContainer>
          <Content
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </DescriptionContainer>

    </PostContainer>
    
  </Root>
);

const Root = styled.section`
  margin-bottom: ${rhythm(1)};

  a {
    box-shadow: none;
  }
`;

const PostContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: ${rhythm(1)} 0 0;
`;

const CoverContainer = styled.div`
  flex: 1 0 0;
`;

const DetailsContainer = styled.div`
  flex: 4 0 0;
  margin: 0 ${rhythm(1)} 0
`;

const DescriptionContainer = styled.div`
  flex: 1 100%;
`;

const Cover = styled(Image)`
  width: 100% !important;
  height: 150px !important;

  @media ${device.tablet} {
    height: 100px !important;
    display: block !important;
    margin-bottom: 2px;
  }
`;

const Title = styled.h3`
  margin: 0 0 ${rhythm(0.5)};
`;

const ContentInfo = styled.span`
  font-style: italic;
  font-size: ${rhythm(0.6)};
`;

const Content = styled.div`
  margin: ${rhythm(2 / 6)} 0;
`;

export default PostCard;
