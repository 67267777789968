import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyLocation } from "local-types";
import Image, { FixedObject } from "gatsby-image";
import { MarkdownRemarkConnection } from "graphql-types";

import PostCard from "./PostCard";
import Author from "../Layout/Author";
import { rhythm } from "../../utils/typography";

interface BlogProps {
  location: GatsbyLocation;
}

const Blog: React.FunctionComponent<BlogProps> = ({
  location,
}): React.ReactElement => {
  const data: {
    allMarkdownRemark: MarkdownRemarkConnection;
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { draft: { eq: false } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              cover {
                childImageSharp {
                  fixed(height: 512) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            timeToRead
          }
        }
      }
    }
  `);

  const posts = data.allMarkdownRemark.edges;
  const postsLength = posts.length;

  return (
    <Root>
      <Author />
      {posts.map(
        ({ node }, index): React.ReactElement => {
          const title = node.frontmatter.title || node.fields.slug;
          let isLastPost = false;
          if (postsLength === index + 1) {
            isLastPost = true;
          }

          return (
            <div key={node.fields.slug}>
              <PostCard
                key={node.fields.slug}
                title={title}
                slug={node.fields.slug}
                cover={
                  node.frontmatter?.cover?.childImageSharp.fixed as FixedObject
                }
                description={node.frontmatter.description || node.excerpt}
                date={node.frontmatter.date}
                timeToRead={node.timeToRead}
                location={location}
              />
              { isLastPost ? null : <Separator /> }
            </div>
          );
        }
      )}
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${rhythm(24)};
`;

const Separator = styled.hr`
  display: flex;
  color: #CCC;
  flex-direction: column;
  margin: auto;
  max-width: ${rhythm(24)};
`;

export default Blog;
